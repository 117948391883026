import React from "react";
import { graphql } from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Header from "/src/components/pages/products/Header";
// import KeyFeatures from "/src/components/pages/products/KeyFeatures";
// import UseCasesSecondary from "/src/components/pages/products/UseCasesSecondary";
// import Benefits from "/src/components/pages/products/Benefits";
// import Functions from "/src/components/pages/products/Functions";
//
// import { LottieAnimation } from "../../components/styled/lotti/LottieAnimations";
//
// import circuit2 from "/static/assets/animations/circuit/circuit02.json";
// import RelatedContentSection from "../../components/pages/products/RelatedContentSection";

const ZorpGateway = ({ data }) => {
  const { gateway } = data;

  return (
    <Layout lang={gateway.lang} seo={gateway.SEO}>
      <Header
        title_white={gateway.product.title_white}
        title_color={gateway.product.title_color}
        brand_color="zorp-gateway-primary"
        subtitle={gateway.product.subtitle}
        description={gateway.product.description}
        url={gateway.product.url}
        url_download={gateway.product.url_download}
        src={gateway.product.product_icon.url}
      />
      {/*<KeyFeatures*/}
      {/*  big_title={gateway.key_features_title}*/}
      {/*  cards={gateway.Key_features}*/}
      {/*/>*/}
      {/*<UseCasesSecondary*/}
      {/*  big_title={gateway.Use_cases_title}*/}
      {/*  cases={gateway.Use_cases}*/}
      {/*  button={gateway.use_cases_download}*/}
      {/*  button_style="zorp-gateway-primary"*/}
      {/*/>*/}
      {/*<Benefits*/}
      {/*  big_title={gateway.benefits_title}*/}
      {/*  benefits={gateway.benefit_card}*/}
      {/*  button={gateway.benefits_button}*/}
      {/*  button_style="zorp-gateway-primary"*/}
      {/*/>*/}
      {/*<LottieAnimation animationData={circuit2} left="true" />*/}
      {/*<Functions*/}
      {/*  big_title={gateway.function_title}*/}
      {/*  cards={gateway.function_card}*/}
      {/*  button={gateway.function_button}*/}
      {/*  button_download={gateway.function_button_download}*/}
      {/*  button_color="zorp-gateway-primary"*/}
      {/*/>*/}
      {/*<RelatedContentSection*/}
      {/*  locale={gateway.lang}*/}
      {/*  related1_slug={gateway.related_content.related1_slug}*/}
      {/*  related2_slug={gateway.related_content.related2_slug}*/}
      {/*  related3_slug={gateway.related_content.related3_slug}*/}
      {/*  related4_slug={gateway.related_content.related4_slug}*/}
      {/*/>*/}
    </Layout>
  );
};

export const query = graphql`
  query GetSingleGateway($locale: String) {
    gateway: strapiZorpGateways(lang: { eq: $locale }) {
      lang
      product: Header {
        title_color
        title_white
        subtitle
        description
        url {
          name
          link
        }
        product_icon {
          url
        }
      }
      SEO {
        title
        isIndexable
        description
        keywords
        preview {
          url
        }
      }
    }
  }
`;

export default ZorpGateway;
