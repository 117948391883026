import React from "react";
import parse from 'html-react-parser';

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid"

import {Body, Header2, Span, Title} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {ImageLogo} from "../../styled/pages/products/header/HeaderComponents";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";
import {ButtonA, ButtonLink, ButtonContainer} from "../../styled/button/ButtonComponents";
import {InlineGrid} from "../../styled/grid/Grids";
import {LottieAnimation} from "../../styled/lotti/LottieAnimations";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const Header = ({
                    title_white,
                    title_color,
                    brand_color,
                    subtitle,
                    description,
                    description2,
                    url,
                    url_a,
                    url_download,
                    src,
                    isLongDesc,
                    invertLotti,
                    customPadding
                }) => {

    return (
        <>
            <LottieAnimation animationData={circuit} left={!invertLotti ? "" : "true"}
                             turned={invertLotti ? "true" : ""}/>
            <SectionLayoutGrid id={title_color} customBackground="middle" customPadding={customPadding}>
                <Col span={6} center>
                    <Title color="white" marginBottom="normal">
                        {title_white}
                        {title_color && <><br/><Span color={brand_color}>{title_color}</Span></>}
                    </Title>
                    {subtitle && <Header2 marginBottom="small" small color="white">{parse(subtitle)}</Header2>}
                    {src &&
                    <OnlyMobile>
                        <Col marginBottom="medium" center>
                            <ImageLogo alt={title_white + " " + title_color} src={src} className="image-for-modal"/>
                        </Col>
                    </OnlyMobile>
                    }
                    {!isLongDesc && <Body color="body-text">{parse(description)}</Body>}
                    {(url || url_download || url_a) &&
                    <ButtonContainer>
                        {url && <ButtonLink to={url.link} color={brand_color}>{url.name}</ButtonLink>}
                        {url_a &&
                        <ButtonA href={url_a.link} target="_blank" rel="noreferrer"
                                 color={brand_color}>{url_a.name}</ButtonA>}
                        {url_download &&
                        <ButtonA href={url_download.file.url} target="_blank" rel="noreferrer"
                                 color="secondary-brand">{url_download.name}</ButtonA>}
                    </ButtonContainer>
                    }
                </Col>
                {src &&
                <Col span={6} center>
                    <OnlyDesktop>
                        <ImageLogo alt={title_white + " " + title_color} src={src}
                                   className="image-for-modal"/>
                    </OnlyDesktop>
                </Col>
                }
                {isLongDesc &&
                <Col>
                    <InlineGrid>
                        <Col span={6}>
                            <Body color="body-text" marginTop="medium">{parse(description)}</Body>
                        </Col>
                        {description2 &&
                        <Col span={6}>
                            <Body color="body-text" marginTop="medium">{parse(description2)}</Body>
                        </Col>
                        }
                    </InlineGrid>
                </Col>
                }
            </SectionLayoutGrid>
        </>
    )
};

export default Header;