import styled from "styled-components";

export const ImageLogo = styled.img`
  cursor: pointer;
  width: 100%;
  transform: ${props => props.index && `scale(1.2)`};
  place-self: center;
  @media screen and (max-width: 800px) {
    margin-top: 1rem;
    transform: ${props => props.index && `scale(1)`};
  }
`